window.sumFormatter = function(data) {
    var field = this.field;

    var total_sum = data.reduce(function(sum, row) {
        price = row[field].replace('<div style=" display: block-inline;" class="text-right ">', '')
            .replace('<div style="color: red; display: block-inline;" class="text-right ">', '')
            .replace('$ ', '')
            .replace(/,/g, '')
            .replace('</div>', '');
        // console.log( price );
        return (sum) + (parseFloat(price) || 0);
    }, 0);
    return '<div style="display:inline;" class="text-right footer-sum">$ ' + total_sum.toLocaleString() + '</div>';
}

window.trueFormatter = function(value) {
    if ( value ){
        str = ((value === "true") || (value === "是")) ? "是" : "否";
        var color = ((value === "true") || (value === "是")) ? "green" : "red";
        return '<div style="color: ' + color + '">' +
        str +
        '</div>';
    } else {
        return null;
    }
}

window.responseHandler = function(res) {
    $.each(res.rows, function(i, row) {
        row.state = $.inArray(row.id, selections) !== -1
    })
    return res
}